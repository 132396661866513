import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from 'reflexbox/styled-components'
// import ContactForm from '../components/contact/form'
import { PageWrap, PageHead, PageRow, PageCopy } from '../components/global'
import SEO from '../components/seo'
// import theme from '../styles/theme'
// import LogomarkAlt from '../svg/logomark-alt'
// import FootCopy from '../components/nav/foot-copy'

export default () => {
  return (
    <PageWrap>
      <SEO
        title="Privacy
Policy"
        description="What are J.H.Dixon’s privacy obligations?"
      />
      <PageRow>
        <PageHead>
          Privacy
          <br />
          Policy
        </PageHead>
        <Flex flexWrap={[`wrap`]} px={[2, 3]} pb={[3, 0]}>
          <Box
            ml={[0, `${(2 / 10) * 100}%`]}
            mr={[0, `${(2 / 10) * 100}%`]}
            width={[1, `${(6 / 10) * 100}%`]}
            pt={[3, 4]}
          >
            <PageCopy>
              <h3>What are J.H.Dixon’s privacy obligations?</h3>

              <p>
                J.H.Dixon Pty Ltd ABN 64 136 082 909 (J.H.Dixon, we, our, us)
                privacy obligations concern the collection, use, disclosure and
                storage of personal information. In dealing with personal
                information, J.H.Dixon is subject to the Privacy Act 1988 (Cth)
                (Privacy Act), the Australian Privacy Principles and any
                applicable State or Territory privacy laws.
              </p>
              <h3>
                J.H.Dixon's Personal Information Handling Practices
                <br /> <br />
                What is personal information?
              </h3>
              <p>
                "Personal information" means information or an opinion (whether
                true or not, and whether it is recorded in a material form or
                not) about an identified individual, or an individual who is
                reasonably identifiable.
              </p>

              <h3>
                What personal information does J.H.Dixon collect and hold?
              </h3>

              <p>
                The types of information we collect and hold includes (but is
                not limited to) personal information about customers,
                prospective employees and contractors, which may include, for
                example, an individual's name, title, email address, address,
                organisation, financial information (such as a customer's bank
                statements) and phone number.
              </p>
              <p>
                We collect and hold personal information from individuals who
                communicate with us directly via telephone, email, in person, or
                through our website (including prospective employees and
                individuals who are contacting us on behalf of a business that
                wishes to engage our services or enquire about our products). We
                may also collect your personal information from third parties
                for the purpose of conducting our business. These third parties
                may include but are not limited to land agents, finance brokers
                and third party referrers. We will only collect your personal
                information from third parties with your consent, unless it is
                unreasonable or impracticable to do so.
              </p>
              <p>
                You can always choose to deal with us anonymously (or by
                providing a pseudonym), unless it is impracticable for us to
                deal with you on that basis.
              </p>
              <p>
                We usually collect information directly from you when you visit
                a display centre, make a phone call, send us an email or submit
                personal information to us via our website or through a written
                application. If you choose not to provide your personal
                information to J.H.Dixon this may affect your ability to enquire
                about or engage our services, enquire about prospective
                employment with us or utilise the functionalities of our
                website.
              </p>
              <p>
                For what purposes does J.H.Dixon collect, hold, use and disclose
                your personal information?
              </p>
              <p>
                We collect, hold, use and disclose your personal information for
                the purpose of providing our products and services, conducting
                our business, communicating with you, promoting our business and
                complying with our legal obligations.
              </p>
              <p>
                Some examples of the specific purposes for which we may collect,
                hold, use and disclose your personal information include the
                following:
              </p>

              <ul>
                <LiWrap>
                  responding to a business query regarding our products or
                  services;
                </LiWrap>
                <LiWrap>
                  managing, operating and improving our website and services;
                </LiWrap>
                <LiWrap>
                  dealing with enquiries regarding prospective employment with
                  us;
                </LiWrap>
                <LiWrap>
                  entering into a business transaction, including but not
                  limited to providing quotes and orders; and,
                </LiWrap>
                <LiWrap>
                  promoting our products, services and offers to you through a
                  range of mediums, including but not limited to email, social
                  media and third party online advertising on websites such as
                  Facebook and Google.
                </LiWrap>
              </ul>
            </PageCopy>

            <PageCopy>
              <h3>Overseas disclosures</h3>

              <p>
                We may disclose your personal information to recipients which
                are located outside of Australia, including the United States of
                America. We will only disclose your personal information to
                overseas recipients if you consent to the disclosure, if we have
                taken steps which are reasonable in the circumstances to ensure
                that the overseas recipient does not breach the Australian
                Privacy Principles as required under the Privacy Act, or if the
                disclosure is permitted under the Privacy Act.
              </p>
              <p>
                J.H.Dixon stores the personal information we hold on an internal
                secure computerised database which is located in Australia.
              </p>
              <p>
                How can you elect to stop receiving marketing communications
                from us?
              </p>
              <p>
                We may use your personal information to send you marketing and
                promotional information we will give you the option to opt out
                from that specific communication, including by using a link to
                unsubscribe from future communications.{' '}
              </p>
              <p>
                When we use your personal information for targeted advertising
                on third party websites like Facebook, we will take reasonable
                steps to ensure that the third party provider gives you the
                option to opt out from targeted online advertising from
                J.H.Dixon. If you wish to opt out of receiving sponsored
                advertisements from J.H.Dixon on Facebook, you can do so by
                firstly clicking the cancel symbol (X) on the top right corner
                of our advertisements on Facebook, and secondly, by contacting
                us directly to let us know. As Facebook controls the display of
                your ad preferences, please refer to Facebook's page titled
                About Facebook Adverts for an explanation on how to adjust your
                Facebook ad preferences, available at Facebook.{' '}
              </p>
              <p>
                If you do not wish to receive direct marketing from us or from
                third parties engaged by us, please let us know by contacting us
                on 0410 545 945 or at&nbsp;
                <a
                  href="mailto:info@jhdixon.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@jhdixon.com.au
                </a>
                . By electing not to opt-out, we assume that you have given
                consent to receive similar marketing communications and
                advertising from us in the future.
              </p>
            </PageCopy>

            <PageCopy>
              <h3>How does J.H.Dixon use cookies?</h3>

              <p>
                A cookie is a small text file stored by the web browser software
                on your computer when you access our website. You can choose to
                disable cookies through your web browser settings, but if you do
                this you may not be able to use the full functionality of our
                website. We use cookies to maintain our website and enhance your
                experience when using our website. We also use cookies as a
                method of targeting our advertising to you if you have
                previously visited our website via remarketing services
                including but not limited to, Google AdWords. Third party
                vendors, including Google, may use cookies to show our
                advertisements to you when you visit websites owned by third
                parties. You can opt out of Google's use of cookies by adjusting
                your Google Ad Settings at&nbsp;
                <a
                  href="http://www.google.com/settings/ads/onweb/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.google.com/settings/ads/onweb/
                </a>
                , or alternatively, by visiting the Network Advertising
                Initiative opt out page, which is available at&nbsp;
                <a
                  href="http://www.networkadvertising.org/choices/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.networkadvertising.org/choices/
                </a>
                . We will not attempt to identify anonymous users or your
                browsing activities except in the unlikely event of an
                investigation, where a law enforcement agency may exercise a
                warrant to inspect the Internet Service Provider's log files.
                Our website may contain links to other websites that are not
                controlled or owned by us. We are not responsible for these
                websites, the privacy policies or practices of those websites,
                or for any consequences arising from your use of those
                websites. We recommend that you read the privacy policies made
                available on those websites to understand their privacy
                practices.
              </p>
            </PageCopy>

            <PageCopy>
              <h3>External websites and webpages</h3>
              <p>
                Our website and this Privacy Policy may contain links to other
                websites that are not controlled or owned by us. We are not
                responsible for these websites, their content or links, the
                privacy policies or practices of those websites, or for any
                consequences arising from your use of those websites. We
                recommend that you read the privacy policies and any other
                relevant information made available on those websites to
                understand their privacy practices. How does J.H.Dixon protect
                your personal information? J.H.Dixon takes reasonable steps to
                protect personal information that is held by us from misuse,
                interference and loss, and unauthorised access, modification or
                disclosure. We will permanently destroy or de-identify personal
                information we hold about you if we no longer need it for any
                purpose.
              </p>
            </PageCopy>

            <PageCopy>
              <h3>
                Queries, Concerns & Further Information
                <br />
                How can I access the information held about me and seek
                correction?
              </h3>
              <p>
                We have measures in place to ensure that the information we hold
                about you is accurate, complete and up-to-date before acting on
                it. If you learn that personal information we hold about you is
                inaccurate, incomplete or not up-to-date you should contact us
                so that your information can be updated. If you wish to see what
                information we hold about you, you can ask for a copy of it. We
                will not charge you for lodging a request for a copy of your
                personal information but you may be asked to pay a reasonable
                fee for the work involved in providing you with this information
                and for associated costs such as photocopying. You will be
                notified of any likely costs before your request is processed.
              </p>
            </PageCopy>
            <PageCopy>
              <h3>What if I have a complaint?</h3>
              <p>
                If you have a privacy complaint or concern, especially if you
                think your privacy has been affected or you wish to complain
                about a refusal to update or grant access to our records of your
                personal information, you should contact us as detailed below
                for an examination of your complaint. If after that you are
                still unhappy you can complain about a privacy matter to the
                Australian Information Commissioner. See{' '}
                <a
                  href="www.oaic.gov.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.oaic.gov.au
                </a>{' '}
                for how to make a complaint. We may update this Privacy Policy
                from time to time. To see our most current Privacy Policy,
                please visit our website at&nbsp;
                <a href="www.jhdixon.com.au">http://www.jhdixon.com.au</a>&nbsp;
                or contact us.
              </p>
            </PageCopy>
            <PageCopy>
              <h3>How can you contact us?</h3>
              <p>
                Email:&nbsp;
                <a
                  href="mailto:info@jhdixon.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@jhdixon.com.au
                </a>
                <br />
                Phone: 0410 545 945 <br />
                Postal address: 1L / 24 Macquarie Street Tenerrife Q 4005
              </p>
            </PageCopy>
          </Box>
        </Flex>
      </PageRow>
    </PageWrap>
  )
}

const LiWrap = styled.li`
  list-style-type: disc;
`
